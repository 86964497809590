<template>
    <div>
        <section class="relative block py-20 overflow-hidden leading-6 text-left bg-white bg-indigo-900">
            <div class="w-full max-w-5xl px-8 mx-auto leading-6 text-left xl:px-12">
                <div
                    class="relative w-full px-4 leading-6 text-center xl:flex-grow-0 xl:flex-shrink-0 lg:flex-grow-0 lg:flex-shrink-0">
                    <div class="box-border text-2xl font-semibold text-indigo-300 uppercase">
                        Terms and Conditions
                    </div>
                    <p class="box-border mx-0 mt-6 mb-0 text-sm font-normal leading-normal text-indigo-100 sm:text-lg">
                        Last updated: <i>May 19, 2021</i><br/>
                        Please read these terms and conditions carefully before using Our Service.
                    </p>
                </div>
            </div>
        </section>

        <!-- Section 2 -->
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">1. Terms</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        By accessing this Website, accessible from https://tokengen.net, you are agreeing to be bound by
                        these Website Terms and Conditions of Use and agree that you are responsible for the agreement
                        with any applicable local laws. If you disagree with any of these terms, you are prohibited from
                        accessing this site. The materials contained in this Website are protected by copyright and
                        trade mark law.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">2. Use License</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Permission is granted to temporarily download one copy of the materials on Tokengen's Website
                        for personal, non-commercial transitory viewing only. This is the grant of a license, not a
                        transfer of title, and under this license you may not:<br/>

                        <li>modify or copy the materials;
                        <li>use the materials for any commercial purpose or for any public display;
                        <li>attempt to reverse engineer any software contained on Tokengen's Website;
                        <li>remove any copyright or other proprietary notations from the materials; or
                    <li>transferring the materials to another person or "mirror" the materials on any other server.
                    </li>
                    <br/>
                    This will let Tokengen to terminate upon violations of any of these restrictions. Upon termination,
                    your viewing right will also be terminated and you should destroy any downloaded materials in your
                    possession whether it is printed or electronic format.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">3. Disclaimer</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        All the materials on Tokengen’s Website are provided "as is". Tokengen makes no warranties, may
                        it be expressed or implied, therefore negates all other warranties. Furthermore, Tokengen does
                        not make any representations concerning the accuracy or reliability of the use of the materials
                        on its Website or otherwise relating to such materials or any sites linked to this Website.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">4. Limitations</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Tokengen or its suppliers will not be hold accountable for any damages that will arise with the
                        use or inability to use the materials on Tokengen’s Website, even if Tokengen or an authorize
                        representative of this Website has been notified, orally or written, of the possibility of such
                        damage. Some jurisdiction does not allow limitations on implied warranties or limitations of
                        liability for incidental damages, these limitations may not apply to you.
                    </p>
                </div>

            </div>
        </section>

        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">5. Revisions and Errata</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        The materials appearing on Tokengen’s Website may include technical, typographical, or
                        photographic errors. Tokengen will not promise that any of the materials in this Website are
                        accurate, complete, or current. Tokengen may change the materials contained on its Website at
                        any time without notice. Tokengen does not make any commitment to update the materials.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">6. Links</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Tokengen has not reviewed all of the sites linked to its Website and is not responsible for the
                        contents of any such linked site. The presence of any link does not imply endorsement by
                        Tokengen of the site. The use of any linked website is at the user’s own risk.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">7. Site Terms of Use Modifications</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Tokengen may revise these Terms of Use for its Website at any time without prior notice. By
                        using this Website, you are agreeing to be bound by the current version of these Terms and
                        Conditions of Use.
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">8. Your Privacy</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Please read our <router-link :to="{name:'privacy-policy'}" class="underline">Privacy Policy.</router-link>
                    </p>
                </div>

            </div>
        </section>
        <section class="w-full px-8 py-16 bg-white xl:px-0">
            <div class="flex flex-col max-w-6xl mx-auto md:flex-row">
                <div class="w-full pr-5 md:w-4/12 xl:pr-12">
                    <h3 class="text-2xl font-bold leading-7">9. Governing Law</h3>
                </div>

                <div class="w-full mt-5 md:mt-0 md:w-4/5 md:pl-2">
                    <p class="text-base font-normal leading-none text-gray-700 md:text-lg">
                        Any claim related to Tokengen's Website shall be governed by the laws of Denmark without regards
                        to its conflict of law provisions.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "tos"
}
</script>

<style scoped>

</style>
